<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <login-links-comp></login-links-comp>
                <div class="logo_panel">
                    <div class="logo">
                        <img src="../../assets/images/logo/oneplay/oneplay_logo.png">
                    </div>
                    <div class="ment line">
                        스포츠 게임 <span style="color: #079e39">원플레이</span>에 오신것을 환영합니다.
                    </div>
                    <div class="ment mt10" style="font-size: 12px;color: #c4c4c3">
                        <p>국내 ONEPLAY는 만 18세 이상만 이용 가능한 서비스입니다. (미성년자는 가입할 수 없습니다)</p>
                        <p>원플레이에서는 회원 당 1개의 계정만 가입이 가능합니다.</p>
                    </div>
                   <div class="input_panel">
                       <label>
                           <input type="text" placeholder="아이디" v-model="user.username">
                       </label>
                       <label>
                           <input type="password" placeholder="패스워드" v-model="user.passwd">
                       </label>
                       <button class="login" @click="doLogin">
                           로그인
                       </button>
                   </div>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>
        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import {getLastBuyItem, getUserInfo, login} from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import sportsConst from "../../common/sportsConst";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import LoginLinksComp from "../../components/links/LoginLinksComp";
    import {RECEIVE_IS_LOGIN, RECEIVE_USER_INFO} from "../../store/mutation-types";

    export default {
        name: "login",
        components: {
            LoginLinksComp,
            FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                },
                isShowMessage: true,
            }
        },
        methods: {
            doLogin() {
                login(this.user).then(res => {
                    if (res.data.success) {
                        getUserInfo().then(res => {
                            this.$store.commit(RECEIVE_IS_LOGIN, true);
                            getLastBuyItem().then(res=>{
                                if(res.data.success){
                                    this.$store.lastBuyItem = res.data.data
                                }
                            })
                            this.$store.state.isLogin = true
                            let userInfo = res.data.data
                            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            this.$router.push('/main').catch(error => {
                                console.info(error.message)
                            });
                        }, err => {
                            console.log(err)
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'warning',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        if(res.data.status === sportsConst.AUTH_DATE_OVER){
                            this.$router.push('/realnameauth').catch(error => {
                                console.info(error.message)
                            });
                        }
                    }
                })
            }
        },
        computed: {
            allowUsername() {
                return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
            },
            allowPasswd() {
                return /^.{3,30}$/.test(this.passwd)
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/lore.css");

</style>